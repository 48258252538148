* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    color: #525354;
    background: #FFFFFF;
}

body, input, button, textarea {
    font: 600 18px
}

body {
    -webkit-font-smoothing: antialiased;
}

body, input, button, textarea, select {
    font: 600 12px Montserrat, sans-serif;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

button {
    cursor: pointer;
    border: 0;
}

button:focus {
    outline: 0;
}

input {
    border: 0;
}

textarea:focus, input:focus {
    outline: none;
}